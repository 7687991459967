import { createRouter, createWebHashHistory } from 'vue-router'
import { useMainStore } from '../stores'
import { usePolicyholderStore } from '@/stores/policyholder'
import { useSplitioStore } from '@/stores/splitio'
import Dashboard from '../views/Dashboard.vue'
import Invoices from '../views/Invoices.vue'
import DetailInvoices from '../views/DetailInvoices.vue'
import Claims from '../views/Claims.vue'
import Policy from '../views/Policy.vue'
import Account from '../views/Account.vue'
import LogIn from '../views/LogIn.vue'
import LogInShopify from '../views/LogInShopify.vue'
import LogInEmail from '../views/LogInEmail.vue'
import SignUp from '../views/SignUp.vue'
import SignUpShopify from '../views/SignUpShopify.vue'
import SignUpEmail from '../views/SignUpEmail.vue'
import Auth from '../views/Auth.vue'
import LogOut from '../views/LogOut.vue'
import ManualClaimForm from '../views/ManualClaimForm.vue'
import DetailClaims from '../views/DetailClaims.vue'
import DetailDamageClaims from '../views/DetailDamageClaims.vue'
import RecoverPassword from '../views/RecoverPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import { allowedToRedirect, hasRedirectQueryParam } from '../utils/redirect'
import SetPasswordAPI from '../components/api-ui/SetPasswordAPI.vue'
import ReferralAuth from '../views/ReferralAuth.vue'
import Uploads from '../views/Uploads.vue'
import OnboardingPanel from '@/components/onboarding-v2/OnboardingPanel.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/Invoices',
    name: 'Invoices',
    component: Invoices,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/Invoices/Details/:invoice_id',
    props: true,
    name: 'DetailInvoices',
    component: DetailInvoices,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/Claims',
    name: 'Claims',
    component: Claims,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/Claims/Details/:id',
    props: true,
    name: 'DetailClaims',
    component: DetailClaims,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/Damage/Details/:id',
    props: true,
    name: 'DetailDamageClaims',
    component: DetailDamageClaims,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/Policy',
    name: 'Policy',
    component: Policy,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/Account',
    name: 'Account',
    component: Account,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/LogIn',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/LogInShopify',
    name: 'LogInShopify',
    component: LogInShopify
  },
  {
    path: '/LogInEmail',
    name: 'LogInEmail',
    component: LogInEmail
  },
  {
    path: '/RecoverPassword',
    name: 'RecoverPassword',
    component: RecoverPassword
  },
  {
    path: '/ResetPassword/:token',
    props: true,
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/SignUp',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/SignUpShopify',
    name: 'SignUpShopify',
    component: SignUpShopify
  },
  {
    path: '/signupemail',
    name: 'SignUpEmail',
    component: SignUpEmail
  },
  {
    path: '/Auth/',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/LogOut/',
    name: 'LogOut',
    component: LogOut,
    meta: {
      requireLogin: true,
      skipOnboarding: true
    }
  },
  {
    path: '/CancelDirectDebit/',
    name: 'CancelDirectDebit',
    component: Invoices,
    meta: {
      requireLogin: true,
      showModal: true,
      canRedirectAfterLogin: true
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/ManualClaimForm',
    name: 'ManualClaimForm',
    component: ManualClaimForm,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/SetPassword/:token',
    name: 'SetPasswordAPI',
    component: SetPasswordAPI
  },
  {
    path: '/ReferralAuth/:source',
    name: 'ReferralAuth',
    component: ReferralAuth,
    props: true
  },
  {
    path: '/Uploads',
    name: 'Uploads',
    component: Uploads,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/Onboarding',
    name: 'Onboarding',
    component: OnboardingPanel,
    props: true,
    meta: {
      requireLogin: true,
      skipOnboarding: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// Check if the user is authenticated
router.beforeEach(async (to, from, next) => {
  const store = useMainStore()
  const policyholderStore = usePolicyholderStore()
  const splitioStore = useSplitioStore()

  const routeRequireLogin = to.matched.some(record => record.meta.requireLogin)
  if (routeRequireLogin) {
    await policyholderStore.fetchPolicyholder()
      .catch(() => {
        this.$router.push('LogOut')
      })
  }

  if (routeRequireLogin && !store.isAuthenticated) {
    if (hasRedirectQueryParam(to) && allowedToRedirect(to.path, router)) {
      sessionStorage.setItem('redirect_target', to.path)
      next({ name: 'LogIn' })
    } else {
      next({ name: 'SignUp' })
    }
  }

  if (await splitioStore.featureFlag(splitioStore.featureFlagsDict.NEW_ONBOARDING)) {
    if (to.matched.some(record => record.meta.requireLogin && !record.meta.skipOnboarding) && !policyholderStore.completed_onboarding) {
      next({ name: 'Onboarding' })
    } else if (to.matched.some(record => record.name === 'Onboarding') && policyholderStore.completed_onboarding) {
      next({ name: 'Dashboard' })
    } else {
      next()
    }
  } else {
    if (to.matched.some(record => record.name === 'Onboarding')) {
      next({ name: 'Dashboard' })
    } else {
      next()
    }
  }
})

export default router
